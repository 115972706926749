import React from 'react';

const testimonials = [
	{
		body: 'TrackNList has revolutionized the way I manage my inventory. The real-time sync feature is a game-changer!',
		author: {
			name: 'John Doe',
			handle: 'johndoe',
			imageUrl: 'https://randomuser.me/api/portraits/men/1.jpg',
		},
	},
	{
		body: 'Cross-listing my products across multiple marketplaces has never been easier. This tool saves me so much time!',
		author: {
			name: 'Jane Smith',
			handle: 'janesmith',
			imageUrl: 'https://randomuser.me/api/portraits/women/2.jpg',
		},
	},
	{
		body: 'The email tracking and notification features keep me informed about every action. I love how organized everything is now.',
		author: {
			name: 'Alice Johnson',
			handle: 'alicejohnson',
			imageUrl: 'https://randomuser.me/api/portraits/women/3.jpg',
		},
	},
	{
		body: 'Customer engagement has improved significantly since I started using TrackNList. Highly recommend it!',
		author: {
			name: 'Bob Brown',
			handle: 'bobbrown',
			imageUrl: 'https://randomuser.me/api/portraits/men/4.jpg',
		},
	},
	{
		body: 'Managing listings and inventory has become so effortless. This tool is a must-have for any serious seller.',
		author: {
			name: 'Emily Davis',
			handle: 'emilydavis',
			imageUrl: 'https://randomuser.me/api/portraits/women/5.jpg',
		},
	},
	{
		body: 'The cross-platform communication feature is fantastic. I can manage all customer messages from one place.',
		author: {
			name: 'Michael Wilson',
			handle: 'michaelwilson',
			imageUrl: 'https://randomuser.me/api/portraits/men/6.jpg',
		},
	},
];

const stats = [
	{ id: 1, name: 'Successful Listings', value: '15k' },
	{ id: 2, name: 'Inventories Synced', value: '10k' },
	{ id: 3, name: 'Emails Tracked', value: '70k' },
];

export default function Reviews() {
	return (
		<>
			<div className="bg-white py-24 sm:py-32 dark:bg-gray-800">
				<div className="mx-auto max-w-7xl px-6 lg:px-8">
					<div className="mx-auto max-w-xl text-center">
						<h2 className="text-lg font-semibold leading-8 tracking-tight text-indigo-600">What Our Users Are
							Saying</h2>
						<p className="mt-2 text-3xl font-bold dark:text-white tracking-tight text-gray-900 sm:text-4xl">
							Join Thousands of Happy Sellers Who Love TrackNList
						</p>
					</div>
					<div className="mx-auto mt-16 flow-root max-w-2xl sm:mt-20 lg:mx-0 lg:max-w-none">
						<div className="-mt-8 sm:-mx-4 sm:columns-2 sm:text-[0] lg:columns-3">
							{testimonials.map((testimonial) => (
								<div key={testimonial.author.handle} className="pt-8 sm:inline-block sm:w-full sm:px-4">
									<figure className="rounded-2xl bg-gray-50 p-8 text-sm leading-6">
										<blockquote className="text-gray-900">
											<p>{`“${testimonial.body}”`}</p>
										</blockquote>
										<figcaption className="mt-6 flex items-center gap-x-4">
											<img alt="" src={testimonial.author.imageUrl} className="h-10 w-10 rounded-full bg-gray-50"/>
											<div>
												<div className="font-semibold text-gray-900">{testimonial.author.name}</div>
												<div className="text-gray-600">{`@${testimonial.author.handle}`}</div>
											</div>
										</figcaption>
									</figure>
								</div>
							))}
						</div>
					</div>
				</div>
			</div>
			<div className="bg-white py-24 dark:bg-gray-800 sm:py-32">
				<div className="mx-auto max-w-7xl px-6 lg:px-8">
					<dl className="grid grid-cols-1 gap-x-8 gap-y-16 text-center lg:grid-cols-3">
						{stats.map((stat) => (
							<div key={stat.id} className="mx-auto flex max-w-xs flex-col gap-y-4">
								<dt className="text-base leading-7 text-gray-600 dark:text-gray-400">{stat.name}</dt>
								<dd className="order-first text-3xl font-semibold tracking-tight text-gray-900 sm:text-5xl dark:text-white">
									{stat.value}
								</dd>
							</div>
						))}
					</dl>
				</div>
			</div>
		</>
	);
}
