import { Fragment } from 'react';
import { CheckIcon, MinusIcon } from '@heroicons/react/20/solid';
import {Link} from "react-router-dom";

const tiers = [
	{
		name: 'Basic',
		id: 'tier-basic',
		href: '/signup?plan=Basic',
		priceMonthly: 'Free',
		description: 'Limited version with basic features.',
		mostPopular: false,
	},
	{
		name: 'Premium',
		id: 'tier-premium',
		href: '/signup?plan=Premium',
		priceMonthly: '$50',
		description: 'Full access to advanced features and integrations.',
		mostPopular: true,
	},
	{
		name: 'Enterprise',
		id: 'tier-enterprise',
		href: '/signup?plan=Enterprise',
		priceMonthly: 'Contact',
		description: 'Customized solutions with unlimited features.',
		mostPopular: false,
	},
];

const sections = [
	{
		name: 'Features',
		features: [
			{ name: 'Cross Listing', tiers: { Basic: 'Up to 2 platforms', Premium: 'Up to 5 platforms', Enterprise: 'Unlimited platforms' } },
			{ name: 'Supported Platforms', tiers: { Basic: '2 platforms', Premium: '5 platforms', Enterprise: 'Unlimited platforms' } },
			{ name: 'Email/Inventory Tracking', tiers: { Basic: 'Limited', Premium: 'Full access', Enterprise: 'Full access' } },
			{ name: 'In-App Messages', tiers: { Basic: 'Not available', Premium: 'Messages from platforms', Enterprise: 'Messages and responses' } },
			{ name: 'Inventory Management Tools', tiers: { Basic: true, Premium: true, Enterprise: true } },
			{ name: 'AI Insights', tiers: { Basic: false, Premium: true, Enterprise: true } },
			{ name: 'Export Formats', tiers: { Basic: 'Basic (.txt, .csv)', Premium: 'All formats', Enterprise: 'All formats' } },
			{ name: 'Scheduled and Auto Listing Support', tiers: { Basic: false, Premium: true, Enterprise: true } },
			{ name: 'Team Support', tiers: { Basic: false, Premium: 'Add-on', Enterprise: true } },
		],
	},
	{
		name: 'Support',
		features: [
			{ name: 'Customer Service', tiers: { Basic: 'Limited help', Premium: 'Timely help', Enterprise: 'Dedicated help' } },
			{ name: 'Priority Support', tiers: { Basic: false, Premium: 'Add-on', Enterprise: 'Available (Add-on for 24/7)' } },
			{ name: 'Custom Features', tiers: { Basic: false, Premium: false, Enterprise: 'Contact us for custom requests' } },
		],
	},
	{
		name: 'Add-Ons',
		features: [
			{ name: 'Additional Platforms', tiers: { Basic: '$5 per platform/month', Premium: '$5 per platform/month', Enterprise: 'Included' } },
			{ name: 'Team Support', tiers: { Basic: 'Not available', Premium: '$10/month per additional member', Enterprise: 'Included' } },
			{ name: 'Priority Support', tiers: { Basic: 'Not available', Premium: '$20/month for 24/7 support', Enterprise: '$20/month for 24/7 support' } },
		],
	},
];

function classNames(...classes:any) {
	return classes.filter(Boolean).join(' ');
}

export default function Example() {
	return (
		<div className="bg-transparent py-24 sm:py-32 dark:bg-gray-800">
			<div className="mx-auto max-w-7xl px-6 lg:px-8">
				<div className="mx-auto max-w-4xl text-center">
					<h2 className="text-base font-semibold leading-7 text-indigo-600">Pricing</h2>
					<p className="mt-2 text-4xl font-bold tracking-tight text-gray-900 dark:text-white sm:text-5xl">
						Plans for teams of all sizes
					</p>
				</div>
				<p className="mx-auto mt-6 max-w-2xl text-center text-lg leading-8 text-gray-600 dark:text-gray-300">
					Choose the plan that best fits your needs. Whether you're just starting out or looking to scale, we have a plan for you.
				</p>

				{/* xs to lg */}
				<div className="mx-auto mt-12 max-w-md space-y-8 sm:mt-16 lg:hidden">
					{tiers.map((tier) => (
						<section
							key={tier.id}
							className={classNames(
								tier.mostPopular ? 'rounded-xl bg-gray-400/5 ring-1 ring-inset ring-gray-200' : '',
								'p-8',
							)}
						>
							<h3 id={tier.id} className="text-sm font-semibold leading-6 text-gray-900 dark:text-white">
								{tier.name}
							</h3>
							<p className="mt-2 flex items-baseline gap-x-1 text-gray-900 dark:text-white">
								<span className="text-4xl font-bold">{tier.priceMonthly}</span>
								<span className="text-sm font-semibold">/month</span>
							</p>
							<Link
								to={tier.href}
								aria-describedby={tier.id}
								className={classNames(
									tier.mostPopular
										? 'bg-indigo-600 text-white hover:bg-indigo-500'
										: 'text-indigo-600 ring-1 ring-inset ring-indigo-200 hover:ring-indigo-300',
									'mt-8 block rounded-md px-3 py-2 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600',
								)}
							>
								Buy plan
							</Link>
							<ul role="list" className="mt-10 space-y-4 text-sm leading-6 text-gray-900 dark:text-white">
								{sections.map((section) => (
									<li key={section.name}>
										<ul role="list" className="space-y-4">
											{section.features.map((feature:any) =>
												feature.tiers[tier.name] ? (
													<li key={feature.name} className="flex gap-x-3">
														<CheckIcon aria-hidden="true" className="h-6 w-5 flex-none text-indigo-600" />
														<span>
                              {feature.name}{' '}
															{typeof feature.tiers[tier.name] === 'string' ? (
																<span className="text-sm leading-6 text-gray-300">({feature.tiers[tier.name]})</span>
															) : null}
                            </span>
													</li>
												) : null,
											)}
										</ul>
									</li>
								))}
							</ul>
						</section>
					))}
				</div>

				{/* lg+ */}
				<div className="isolate mt-20 hidden lg:block">
					<div className="relative -mx-8">
						{tiers.some((tier) => tier.mostPopular) ? (
							<div className="absolute inset-x-4 inset-y-0 -z-10 flex">
								<div
									style={{ marginLeft: `${(tiers.findIndex((tier) => tier.mostPopular) + 1) * 25}%` }}
									aria-hidden="true"
									className="flex w-1/4 px-4"
								>
									<div className="w-full rounded-t-xl border-x border-t border-gray-900/10 dark:border-gray-300 bg-gray-400/5" />
								</div>
							</div>
						) : null}
						<table className="w-full table-fixed border-separate border-spacing-x-8 dark:border-gray-300 text-left">
							<caption className="sr-only">Pricing plan comparison</caption>
							<colgroup>
								<col className="w-1/4" />
								<col className="w-1/4" />
								<col className="w-1/4" />
								<col className="w-1/4" />
							</colgroup>
							<thead>
							<tr>
								<td />
								{tiers.map((tier) => (
									<th key={tier.id} scope="col" className="px-6 pt-6 xl:px-8 xl:pt-8">
										<div className="text-sm font-semibold leading-7 text-gray-900 dark:text-white">{tier.name}</div>
									</th>
								))}
							</tr>
							</thead>
							<tbody>
							<tr>
								<th scope="row">
									<span className="sr-only">Price</span>
								</th>
								{tiers.map((tier) => (
									<td key={tier.id} className="px-6 pt-2 xl:px-8">
										<div className="flex items-baseline gap-x-1 text-gray-900 dark:text-white">
											<span className="text-4xl font-bold">{tier.priceMonthly}</span>
											<span className="text-sm font-semibold leading-6">/month</span>
										</div>
										<a
											href={tier.href}
											className={classNames(
												tier.mostPopular
													? 'bg-indigo-600 text-white hover:bg-indigo-500'
													: 'text-indigo-600 dark:text-gray-300 ring-1 ring-inset ring-indigo-200 dark:ring-0 dark:bg-gray-700 hover:ring-indigo-300 dark:hover:bg-gray-600 dark:hover:ring-0',
												'mt-8 block rounded-md px-3 py-2 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600',
											)}
										>
											Buy plan
										</a>
									</td>
								))}
							</tr>
							{sections.map((section, sectionIdx) => (
								<Fragment key={section.name}>
									<tr>
										<th
											scope="colgroup"
											colSpan={4}
											className={classNames(
												sectionIdx === 0 ? 'pt-8' : 'pt-16',
												'pb-4 text-sm font-semibold leading-6 text-gray-900 dark:text-white',
											)}
										>
											{section.name}
											<div className="absolute inset-x-8 mt-4 h-px bg-gray-900/10 dark:bg-gray-300/10" />
										</th>
									</tr>
									{section.features.map((feature:any) => (
										<tr key={feature.name}>
											<th scope="row" className="py-4 text-sm font-normal leading-6 text-gray-900 dark:text-white">
												{feature.name}
												<div className="absolute inset-x-8 mt-4 h-px bg-gray-900/5 dark:bg-gray-300/5" />
											</th>
											{tiers.map((tier:any) => (
												<td key={tier.id} className="px-6 py-4 xl:px-8">
													{typeof feature.tiers[tier.name] === 'string' ? (
														<div className="text-center text-sm leading-6 text-gray-500 dark:text-gray-300">
															{feature.tiers[tier.name]}
														</div>
													) : (
														<>
															{feature.tiers[tier.name] === true ? (
																<CheckIcon aria-hidden="true" className="mx-auto h-5 w-5 text-indigo-600" />
															) : (
																<MinusIcon aria-hidden="true" className="mx-auto h-5 w-5 text-gray-400 dark:text-gray-300" />
															)}

															<span className="sr-only">
                                  {feature.tiers[tier.name] === true ? 'Included' : 'Not included'} in {tier.name}
                                </span>
														</>
													)}
												</td>
											))}
										</tr>
									))}
								</Fragment>
							))}
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
	);
}
