'use client'

import { RocketLaunchIcon, ComputerDesktopIcon} from '@heroicons/react/24/outline'
import {Link} from "react-router-dom";
import Features from "./Features";
import Reviews from "./Reviews";
import FAQs from "./FAQs";
import WaitRoom from "./WaitRoom";
import GrailedImage from "../images/grailed.svg"

const platforms = [
	{ name: 'Mercari', src: 'https://upload.wikimedia.org/wikipedia/commons/thumb/7/7f/Mercari_logo_2018.svg/440px-Mercari_logo_2018.svg.png' },
	{ name: 'Grailed', src: GrailedImage },
	{ name: 'LightSpeed', src: 'https://upload.wikimedia.org/wikipedia/en/thumb/9/99/Lightspeed_Commerce_Logo.svg/440px-Lightspeed_Commerce_Logo.svg.png' },
	{ name: 'Amazon', src: 'https://upload.wikimedia.org/wikipedia/commons/thumb/0/06/Amazon_2024.svg/400px-Amazon_2024.svg.png' },
	{ name: 'Poshmark', src: 'https://upload.wikimedia.org/wikipedia/commons/thumb/4/44/Poshmark_logo.png/440px-Poshmark_logo.png' },
	{ name: 'Stock X', src: 'https://upload.wikimedia.org/wikipedia/commons/thumb/5/58/StockX_logo.svg/440px-StockX_logo.svg.png' },
	{ name: 'Goat', src: 'https://upload.wikimedia.org/wikipedia/commons/thumb/a/a2/GOAT_logo.png/440px-GOAT_logo.png' },
	{ name: 'Shopify', src: 'https://upload.wikimedia.org/wikipedia/commons/thumb/0/0e/Shopify_logo_2018.svg/580px-Shopify_logo_2018.svg.png' },
	{ name: 'eBay', src: 'https://upload.wikimedia.org/wikipedia/commons/thumb/1/1b/EBay_logo.svg/1200px-EBay_logo.svg.png' },
	{ name: 'Facebook', src: 'https://upload.wikimedia.org/wikipedia/commons/thumb/9/93/Facebook_logo_%282023%29.svg/300px-Facebook_logo_%282023%29.svg.png' },
];

const PoweredBy: React.FC = () => {
	return (
		<div className="my-8">
			<p className="text-center text-sm font-semibold text-gray-500 dark:text-gray-300">POWERING SELLERS ACROSS MULTIPLE PLATFORMS</p>
			<div className="mt-6 flex flex-wrap space-y-6 p-6 lg:p-0 lg:space-y-0 justify-center space-x-6">
				{platforms.map((platform) => (
					<img key={platform.name} src={platform.src} alt={platform.name} className="h-4 w-16 cursor-pointer grayscale hover:grayscale-0" />
				))}
			</div>
		</div>
	);
};

export default function Index() {
	return (
		<div className="bg-white">
			<main className={"dark:bg-gray-800"}>
				<div className="relative isolate">
					<svg
						aria-hidden="true"
						className="absolute dark:hiden inset-x-0 top-0 -z-10 h-[64rem] w-full stroke-gray-200 [mask-image:radial-gradient(32rem_32rem_at_center,white,transparent)]"
					>
						<defs>
							<pattern
								x="50%"
								y={-1}
								id="1f932ae7-37de-4c0a-a8b0-a6e3b4d44b84"
								width={200}
								height={200}
								patternUnits="userSpaceOnUse"
							>
								<path d="M.5 200V.5H200" fill="none" />
							</pattern>
						</defs>
						<svg x="50%" y={-1} className="overflow-visible fill-gray-50 dark:fill-gray-900">
							<path
								d="M-200 0h201v201h-201Z M600 0h201v201h-201Z M-400 600h201v201h-201Z M200 800h201v201h-201Z"
								strokeWidth={0}
							/>
						</svg>
						<rect fill="url(#1f932ae7-37de-4c0a-a8b0-a6e3b4d44b84)" width="100%" height="100%" strokeWidth={0} />
					</svg>
					<div
						aria-hidden="true"
						className="absolute left-1/2 right-0 top-0 -z-10 -ml-24 transform-gpu overflow-hidden blur-3xl lg:ml-24 xl:ml-48"
					>
						<div
							style={{
								clipPath:
									'polygon(63.1% 29.5%, 100% 17.1%, 76.6% 3%, 48.4% 0%, 44.6% 4.7%, 54.5% 25.3%, 59.8% 49%, 55.2% 57.8%, 44.4% 57.2%, 27.8% 47.9%, 35.1% 81.5%, 0% 97.7%, 39.2% 100%, 35.2% 81.4%, 97.2% 52.8%, 63.1% 29.5%)',
							}}
							className="aspect-[801/1036] w-[50.0625rem] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30"
						/>
					</div>
					<div className="overflow-hidden">
						<div className="mx-auto max-w-7xl px-6 pb-32 pt-36 sm:pt-60 lg:px-8 lg:pt-32">
							<div className="mx-auto max-w-2xl gap-x-14 lg:mx-0 lg:flex lg:max-w-none lg:items-center">
								<div className="relative w-full max-w-xl lg:shrink-0 xl:max-w-2xl">
									<h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 dark:text-white sm:text-5xl">
										Effortlessly <span className="bg-gradient-to-r from-softBlue via-brightPurple to-deepPurple bg-clip-text text-transparent">Manage, Track and Sell </span> Your Inventory Across Multiple Marketplaces
									</h1>
									<p className="mt-4 text-lg text-gray-700 dark:text-gray-300 sm:mt-6">
										Streamline Your Sales Process with Seamless Integration and Real-Time Inventory tracking and syncing.
										<span className={"text-gray-400"}> Grow with us today!</span> </p>
									<div className="mt-10 flex flex-col space-y-4 md:space-y-0 md:flex-row items-center gap-x-6">
										<Link to={"/signup"}>
										<button
											// onClick={() => {  document.getElementById('waitroom')!.scrollIntoView({ behavior: 'smooth' });}}
											onClick={undefined}
											className="flex items-center h-[70px] justify-center rounded-md bg-softBlue px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-brightPurple focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-softBlue">
											<RocketLaunchIcon aria-hidden={true} className={"mr-2 h-10 w-10"} />
											<div className="flex flex-col my-0 space-y-0 ">
												<span className={"text-xs"}>Free Trial</span>
												<span className={"text-lg"}>Get Started</span>
											</div>
										</button> </Link>
										<Link to={"#"}><button
											className="flex items-center h-[70px] justify-center rounded-md bg-gray-500 px-4 py-2 text-sm font-semibold text-white shadow-sm cursor-not-allowed"
											disabled>
											<ComputerDesktopIcon aria-hidden={true} className={"mr-2 h-10 w-10"} />
											<div className="flex flex-col my-0 space-y-0">
												<span className={"text-xs"}>Coming Soon</span>
												<span>Download for Desktop</span>
											</div>
										</button> </Link>
									</div>
								</div>
								<div className="mt-14 flex justify-end gap-8 sm:-mt-44 sm:justify-start sm:pl-20 lg:mt-0 lg:pl-0">
									<div
										className="ml-auto w-44 flex-none space-y-8 pt-32 sm:ml-0 sm:pt-80 lg:order-last lg:pt-36 xl:order-none xl:pt-80">
										<div className="relative">
											<img
												alt=""
												src="https://images.unsplash.com/photo-1557804506-669a67965ba0?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&h=528&q=80"
												className="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
											/>
											<div className="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" />
										</div>
									</div>
									<div className="mr-auto w-44 flex-none space-y-8 sm:mr-0 sm:pt-52 lg:pt-36">
										<div className="relative">
											<img
												alt=""
												src="https://images.unsplash.com/photo-1485217988980-11786ced9454?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&h=528&q=80"
												className="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
											/>
											<div className="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" />
										</div>
										<div className="relative">
											<img
												alt=""
												src="https://images.unsplash.com/photo-1559136555-9303baea8ebd?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&crop=focalpoint&fp-x=.4&w=396&h=528&q=80"
												className="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
											/>
											<div className="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" />
										</div>
									</div>
									<div className="w-44 flex-none space-y-8 pt-32 sm:pt-0">
										<div className="relative">
											<img
												alt=""
												src="https://images.unsplash.com/photo-1670272504528-790c24957dda?ixlib=rb-4.0.3&ixid=MnwxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&crop=left&w=400&h=528&q=80"
												className="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
											/>
											<div className="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" />
										</div>
										<div className="relative">
											<img
												alt=""
												src="https://images.unsplash.com/photo-1670272505284-8faba1c31f7d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&h=528&q=80"
												className="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
											/>
											<div className="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" />
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<PoweredBy />
				<Features />
				<Reviews />
				<FAQs />
				<WaitRoom />
			</main>
		</div>
	)
}
