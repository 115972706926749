import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import Homepage from './pages/Homepage';
import ContactUs from './pages/ContactUs';
import Pricing from './pages/Pricing';
import TermsAndConditions from './pages/TermsAndConditions';
import PrivacyPolicy from './pages/PrivacyPolicy';
import About from './pages/About';

import Login from './pages/Login';
import Signup from './pages/Signup';
import ForgotPassword from './pages/ForgotPassword';
import NotFound from './pages/NotFound';

const App: React.FC = () => {
	return (
		<BrowserRouter>
			<div id="app">
				<Header />
				<Routes>
					<Route path="/" element={<Homepage />} />
					<Route path="/login" element={<Login />} />
					<Route path="/signup" element={<Signup />} />
					<Route path="/forgot-password" element={<ForgotPassword />} />
					<Route path="/contact-us" element={<ContactUs />} />
					<Route path="/pricing" element={<Pricing />} />
					<Route path="/terms-and-conditions" element={<TermsAndConditions />} />
					<Route path="/privacy-policy" element={<PrivacyPolicy />} />
					<Route path="/about" element={<About />} />
					<Route path="*" element={<NotFound />} />
				</Routes>
				<Footer />
			</div>
		</BrowserRouter>
	);
};

export default App;
