import React from 'react';
import Index from "../components/Index";

const Homepage: React.FC = () => {
	return (
		<div id="homepage">
			<Index />
		</div>
	);
};

export default Homepage;
