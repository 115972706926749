import React from 'react';

import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/react';
import { PlusIcon, MinusIcon } from '@heroicons/react/24/outline';

const faqs = [
	{
		question: 'What is cross-listing?',
		answer: 'Cross-listing allows you to list your products on multiple marketplaces simultaneously, increasing your reach and sales potential.',
	},
	{
		question: 'How does real-time inventory tracking work?',
		answer: 'Real-time inventory tracking keeps your stock levels updated across all platforms, ensuring accurate inventory management and preventing overselling.',
	},
	{
		question: 'Which platforms are supported?',
		answer: 'We support popular marketplaces such as eBay, Amazon, Facebook, Poshmark, Grailed, Vinted, Depop, and Mercari.',
	},
	{
		question: 'How do I manage my listings?',
		answer: 'You can create, edit, and manage your listings through our intuitive interface. All changes are synchronized across all connected platforms.',
	},
	{
		question: 'What is email tracking?',
		answer: 'Email tracking monitors incoming emails for order notifications and updates, keeping you informed about all communications in one place.',
	},
	{
		question: 'How do notifications and alerts work?',
		answer: 'You will receive notifications and alerts for all actions across different platforms, ensuring you stay informed about important updates.',
	},
	{
		question: 'Is my data secure?',
		answer: 'Yes, we use industry-standard security measures to protect your data and ensure that your inventory information is safe and secure.',
	},
	{
		question: 'How do I get started with TrackNList?',
		answer: 'Sign up for a free trial and start managing your inventory and listings effortlessly. Our onboarding process will guide you through the setup.',
	},
	{
		question: 'Are there any fees for using TrackNList?',
		answer: 'We offer various pricing plans to suit different needs. Please visit our pricing page for detailed information on fees and features included in each plan.',
	},
	{
		question: 'Can I customize my listings for different platforms?',
		answer: 'Yes, you can customize product details, prices, and descriptions for each platform to optimize your listings for different audiences.',
	},
	{
		question: 'How do I handle customer messages?',
		answer: 'Our cross-platform communication feature allows you to manage all customer messages from a single dashboard, streamlining your customer support.',
	},
	{
		question: 'What kind of reports can I generate?',
		answer: 'You can generate detailed reports on sales performance, inventory levels, and customer interactions to gain insights and make data-driven decisions.',
	},
	{
		question: 'Is there a mobile app available?',
		answer: 'Currently, we offer a desktop application. A mobile app is in development and will be available soon.',
	},
	{
		question: 'How do I sync my inventory?',
		answer: 'Inventory sync is automated and occurs in real-time. You can also manually trigger a sync if needed through the dashboard.',
	},
	{
		question: 'Can I integrate with other tools?',
		answer: 'Yes, TrackNList supports integrations with various third-party tools and services to enhance your selling experience.',
	},
];

export default function FAQs() {
	return (
		<div className="dark:bg-gray-800">
			<div className="mx-auto max-w-7xl px-6 py-24 sm:py-32 lg:px-8 lg:py-40">
				<div className="mx-auto max-w-4xl dark:divide-white/10 divide-black/10">
					<h2 className="text-2xl font-bold leading-10 tracking-tight dark:text-white text-black">Frequently Asked Questions</h2>
					<dl className="mt-10 space-y-6 divide-y dark:divide-white/10 divide-black/10">
						{faqs.map((faq) => (
							<Disclosure key={faq.question} as="div" className="pt-6">
								<dt>
									<DisclosureButton className="group flex w-full items-start justify-between text-left dark:text-white text-black">
										<span className="text-base font-semibold leading-7">{faq.question}</span>
										<span className="ml-6 flex h-7 items-center">
                      <PlusIcon aria-hidden="true" className="h-6 w-6 group-data-[open]:hidden" />
                      <MinusIcon aria-hidden="true" className="h-6 w-6 [.group:not([data-open])_&]:hidden" />
                    </span>
									</DisclosureButton>
								</dt>
								<DisclosurePanel as="dd" className="mt-2 pr-12">
									<p className="text-base leading-7 dark:text-gray-300 text-black">{faq.answer}</p>
								</DisclosurePanel>
							</Disclosure>
						))}
					</dl>
				</div>
			</div>
		</div>
	);
}
