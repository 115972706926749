import React, { useState } from "react";
import { faDiscord, faTiktok, faXTwitter } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ContactUs: React.FC = () => {
	const [formData, setFormData] = useState({
		firstName: '',
		lastName: '',
		email: '',
		message: ''
	});
	const [errors, setErrors] = useState<{ [key: string]: string }>({});
	const [message, setMessage] = useState<string | null>(null);
	const [messageSuccess, setMessageSuccess] = useState<boolean>(false);
	const [isSubmitting, setIsSubmitting] = useState(false);

	const validateForm = () => {
		const newErrors: { [key: string]: string } = {};
		if (!formData.firstName) {
			newErrors.firstName = 'First name is required';
		}
		if (!formData.lastName) {
			newErrors.lastName = 'Last name is required';
		}
		if (!formData.email) {
			newErrors.email = 'Email is required';
		} else if (!/\S+@\S+.\S+/.test(formData.email)) {
			newErrors.email = 'Email address is invalid';
		}
		if (!formData.message) {
			newErrors.message = 'Message is required';
		}
		return newErrors;
	};

	const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		setFormData({
			...formData,
			[e.target.name]: e.target.value
		});
	};

	const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		const validationErrors = validateForm();
		if (Object.keys(validationErrors).length > 0) {
			setErrors(validationErrors);
			return;
		}

		setIsSubmitting(true);
		try {
			const response = await fetch('https://api.tracknlist.com/api/contact', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json'
				},
				body: JSON.stringify({
					"name": `${formData.firstName} ${formData.lastName}`,
					//@ts-ignore
					"id": (() => 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => (c === 'x' ? (Math.random() * 16 | 0) : ('r&0x3' | '0x8')).toString(16)))(),
					email: formData.email,
					message: formData.message,
					created_at: + Date.now(),
					timestamp: + Date.now(),
				})
			});

			if (response.ok) {
				setMessageSuccess(true);
				setMessage('Your message has been sent successfully.');
			} else {
				const errorData = await response.json();
				setMessage(errorData.error || 'An error occurred. Please try again.');
			}
		} catch (error) {
			setMessage('An error occurred. Please try again.');
		} finally {
			setIsSubmitting(false);
		}
	};

	return (
		<div className="relative isolate bg-transparent dark:bg-gray-800">
			<div className="mx-auto grid max-w-7xl grid-cols-1 lg:grid-cols-2">
				<div className="relative px-6 pb-20 pt-24 sm:pt-32 lg:static lg:px-8 lg:py-48">
					<div className="mx-auto max-w-xl lg:mx-0 lg:max-w-lg">
						<div className="absolute inset-y-0 left-0 -z-10 w-full overflow-hidden ring-1 ring-gray-900/10 dark:ring-white/10 lg:w-1/2">
							<svg
								aria-hidden="true"
								className="absolute inset-0 h-full w-full stroke-gray-200 dark:stroke-gray-700"
							>
								<defs>
									<pattern
										x="100%"
										y={-1}
										id="83fd4e5a-9d52-42fc-97b6-718e5d7ee527"
										width={200}
										height={200}
										patternUnits="userSpaceOnUse"
									>
										<path d="M130 200V.5M.5 .5H200" fill="none" />
									</pattern>
								</defs>
								<rect fill="white" className="dark:fill-gray-800" width="100%" height="100%" strokeWidth={0} />
								<svg x="100%" y={-1} className="overflow-visible fill-gray-50 dark:fill-gray-800">
									<path d="M-470.5 0h201v201h-201Z" strokeWidth={0} />
								</svg>
								<rect fill="url(#83fd4e5a-9d52-42fc-97b6-718e5d7ee527)" width="100%" height="100%" strokeWidth={0} />
							</svg>
						</div>
						<h2 className="text-3xl font-bold tracking-tight text-gray-900 dark:text-white">Welcome to TrackNList!</h2>
						<p className="mt-6 text-lg leading-8 text-gray-600 dark:text-gray-300">
							We're excited to hear from you! Tell us about your experience, suggest new features, or just get in touch.
							Your feedback helps us build a better product.
						</p>
						<dl className="mt-10 space-y-4 text-base leading-7 text-gray-600 dark:text-gray-300">
							<div className="flex gap-x-4">
								<dt className="flex-none">
									<span className="sr-only">Discord</span>
								</dt>
								<dd className={"flex items-center space-x-2"}>
									<FontAwesomeIcon icon={faDiscord} className="h-6 w-6" />
									<a href="https://www.facebook.com" className="hover:text-gray-900 dark:hover:text-white">
										Discord
									</a>
								</dd>
							</div>
							<div className="flex gap-x-4">
								<dt className="flex-none">
									<span className="sr-only">X(Twitter)</span>
								</dt>
								<dd className={"flex items-center space-x-2"}>
									<FontAwesomeIcon icon={faXTwitter} className="h-6 w-6" />
									<a href="https://www.twitter.com" className="hover:text-gray-900 dark:hover:text-white">
										X(Twitter)
									</a>
								</dd>
							</div>
							<div className="flex gap-x-4">
								<dt className="flex-none">
									<span className="sr-only">TikTok</span>
								</dt>
								<dd className={"flex items-center space-x-2"}>
									<FontAwesomeIcon icon={faTiktok} className="h-6 w-6" />
									<a href="https://www.twitter.com" className="hover:text-gray-900 dark:hover:text-white">
										TikTok
									</a>
								</dd>
							</div>
						</dl>
					</div>
				</div>
				<form onSubmit={handleSubmit} className="px-6 pb-24 pt-20 sm:pb-32 lg:px-8 lg:py-48">
					<div className="mx-auto max-w-xl lg:mr-0 lg:max-w-lg">
						{message && (
							<div className={`mb-4 text-sm ${messageSuccess ? "text-green-500 dark:text-green-400" : "text-red-600 dark:text-red-400"}`}>
								{message}
							</div>
						)}
						<div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
							<div>
								<label htmlFor="firstName" className="block text-sm font-semibold leading-6 text-gray-900 dark:text-white">
									First name
								</label>
								<div className="mt-2.5">
									<input
										id="firstName"
										name="firstName"
										type="text"
										value={formData.firstName}
										onChange={handleChange}
										autoComplete="given-name"
										className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 dark:text-gray-100 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-gray-600 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
									/>
									{errors.firstName && (
										<p className="mt-2 text-sm text-red-600 dark:text-red-400">{errors.firstName}</p>
									)}
								</div>
							</div>
							<div>
								<label htmlFor="lastName" className="block text-sm font-semibold leading-6 text-gray-900 dark:text-white">
									Last name
								</label>
								<div className="mt-2.5">
									<input
										id="lastName"
										name="lastName"
										type="text"
										value={formData.lastName}
										onChange={handleChange}
										autoComplete="family-name"
										className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-gray-600 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
									/>
									{errors.lastName && (
										<p className="mt-2 text-sm text-red-600 dark:text-red-400">{errors.lastName}</p>
									)}
								</div>
							</div>
							<div className="sm:col-span-2">
								<label htmlFor="email" className="block text-sm font-semibold leading-6 text-gray-900 dark:text-white">
									Email
								</label>
								<div className="mt-2.5">
									<input
										id="email"
										name="email"
										type="email"
										value={formData.email}
										onChange={handleChange}
										autoComplete="email"
										className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 dark:text-gray-100 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-gray-600 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
									/>
									{errors.email && (
										<p className="mt-2 text-sm text-red-600 dark:text-red-400">{errors.email}</p>
									)}
								</div>
							</div>
							{/*<div className="sm:col-span-2">*/}
							{/*	<label htmlFor="phoneNumber" className="block text-sm font-semibold leading-6 text-gray-900 dark:text-white">*/}
							{/*		Phone number*/}
							{/*	</label>*/}
							{/*	<div className="mt-2.5">*/}
							{/*		<input*/}
							{/*			id="phoneNumber"*/}
							{/*			name="phoneNumber"*/}
							{/*			type="tel"*/}
							{/*			value={formData.phoneNumber}*/}
							{/*			onChange={handleChange}*/}
							{/*			autoComplete="tel"*/}
							{/*			className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 dark:text-gray-100 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-gray-600 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"*/}
							{/*		/>*/}
							{/*	</div>*/}
							{/*</div>*/}
							<div className="sm:col-span-2">
								<label htmlFor="message" className="block text-sm font-semibold leading-6 text-gray-900 dark:text-white">
									Message
								</label>
								<div className="mt-2.5">
                  <textarea
	                  id="message"
	                  name="message"
	                  rows={4}
	                  value={formData.message}
	                  onChange={handleChange}
	                  className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-gray-600 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
									{errors.message && (
										<p className="mt-2 text-sm text-red-600 dark:text-red-400">{errors.message}</p>
									)}
								</div>
							</div>
						</div>
						<div className="mt-8 flex justify-end">
							<button
								type="submit"
								disabled={isSubmitting}
								className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
							>
								{isSubmitting ? (
									<svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
										<circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
										<path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
									</svg>
								) : (
									'Send message'
								)}
							</button>
						</div>
					</div>
				</form>
			</div>
		</div>
	);
};

export default ContactUs;
