import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const ForgetPasswordForm: React.FC = () => {
	const [formData, setFormData] = useState({
		email: ''
	});
	const [errors, setErrors] = useState<{ [key: string]: string }>({});
	const [messageSuccess, setMessageSuccess] = useState<boolean>(false);
	const [message, setMessage] = useState<string | null>(null);
	const [isSubmitting, setIsSubmitting] = useState(false);

	useEffect(() => {
		document.getElementById("header")!.classList.add("bg-white");
		return () => {
			document.getElementById("header")!.classList.remove("bg-white");
		}
	}, []);

	const validateForm = () => {
		const newErrors: { [key: string]: string } = {};
		if (!formData.email) {
			newErrors.email = 'Email is required';
		} else if (!/\S+@\S+.\S+/.test(formData.email)) {
			newErrors.email = 'Email address is invalid';
		}
		return newErrors;
	};

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setFormData({
			...formData,
			[e.target.name]: e.target.value
		});
	};

	const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		const validationErrors = validateForm();
		if (Object.keys(validationErrors).length > 0) {
			setErrors(validationErrors);
			return;
		}

		setIsSubmitting(true);
		try {
			const response = await fetch('https://api.tracknlist.com/api/forgot-password', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json'
				},
				body: JSON.stringify(formData)
			});

			if (response.ok) {
				setMessageSuccess(true);
				setMessage('Password reset link sent. Please check your inbox.');
			} else {
				const errorData = await response.json();
				setMessage(errorData.error || 'An error occurred. Please try again.');
			}
		} catch (error) {
			setMessage('An error occurred. Please try again.');
		} finally {
			setIsSubmitting(false);
		}
	};

	return (
		<>
			<div className="flex min-h-[calc(100vh-163px-80px)] flex-1 dark:bg-gray-800 mt-[80px]">
				<div className="flex flex-1 flex-col justify-center px-4 py-12 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
					<div className="mx-auto w-full max-w-sm lg:w-96">
						<div>
							<h2 className="mt-8 text-2xl font-bold leading-9 tracking-tight text-gray-900 dark:text-white">
								Reset your password
							</h2>
							<p className="mt-2 text-sm leading-6 text-gray-500 dark:text-gray-400">
								Remembered your password?{' '}
								<Link to="/login" className="font-semibold text-indigo-600 hover:text-indigo-500">
									Sign in
								</Link>
							</p>
						</div>

						<div className="mt-10">
							{message && (
								<div className={`mb-4 text-sm ${messageSuccess ? "text-green-500 dark:text-green-400" : "text-red-600 dark:text-red-400"}`}>
									{message}
								</div>
							)}
							<form onSubmit={handleSubmit} className="space-y-6">
								<div>
									<label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">
										Email address
									</label>
									<div className="mt-2">
										<input
											id="email"
											name="email"
											type="email"
											value={formData.email}
											onChange={handleChange}
											required
											autoComplete="email"
											className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 dark:bg-gray-800 dark:text-white"
										/>
										{errors.email && (
											<p className="mt-2 text-sm text-red-600 dark:text-red-400">{errors.email}</p>
										)}
									</div>
								</div>

								<div>
									<button
										type="submit"
										disabled={isSubmitting}
										className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
									>
										{isSubmitting ? (
											<svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
												<circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
												<path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
											</svg>
										) : (
											'Send reset link'
										)}
									</button>
								</div>
							</form>
						</div>
					</div>
				</div>
				<div className="relative hidden w-0 flex-1 lg:block">
					<img
						alt=""
						src="https://images.unsplash.com/photo-1496917756835-20cb06e75b4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1908&q=80"
						className="absolute inset-0 h-full w-full object-cover"
					/>
				</div>
			</div>
		</>
	);
};

export default ForgetPasswordForm;
