import { useState } from 'react';
import { CheckCircleIcon, InformationCircleIcon } from '@heroicons/react/20/solid';

export default function PrivacyPolicy() {
	const [darkMode, setDarkMode] = useState(false);

	return (
		<div className={darkMode ? 'dark' : ''}>
			<div className="bg-white dark:bg-gray-800 px-6 py-32 lg:px-8">
				<div className="mx-auto max-w-3xl text-base leading-7 text-gray-700 dark:text-gray-300">
					<h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 dark:text-white sm:text-4xl">
						Privacy Policy
					</h1>
					<p className="mt-6 text-xl leading-8">
						Welcome to TrackNList. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our service.
					</p>
					<div className="mt-10 max-w-2xl">
						<p>
							By using our service, you agree to the collection and use of information in accordance with this policy. If you do not agree with the terms, please do not use our service.
						</p>
						<ul role="list" className="mt-8 max-w-xl space-y-8 text-gray-600 dark:text-gray-400">
							<li className="flex gap-x-3">
								<CheckCircleIcon aria-hidden="true" className="mt-1 h-5 w-5 flex-none text-indigo-600 dark:text-indigo-400" />
								<span>
                  <strong className="font-semibold text-gray-900 dark:text-white">Information Collection.</strong> We collect various types of information, including personal data, usage data, and tracking information.
                </span>
							</li>
							<li className="flex gap-x-3">
								<CheckCircleIcon aria-hidden="true" className="mt-1 h-5 w-5 flex-none text-indigo-600 dark:text-indigo-400" />
								<span>
                  <strong className="font-semibold text-gray-900 dark:text-white">Use of Information.</strong> The information we collect is used to provide and improve our service, communicate with you, and ensure security.
                </span>
							</li>
							<li className="flex gap-x-3">
								<CheckCircleIcon aria-hidden="true" className="mt-1 h-5 w-5 flex-none text-indigo-600 dark:text-indigo-400" />
								<span>
                  <strong className="font-semibold text-gray-900 dark:text-white">Data Security.</strong> We implement measures to protect your information from unauthorized access, alteration, disclosure, or destruction.
                </span>
							</li>
						</ul>
						<p className="mt-8">
							If you have any questions about this Privacy Policy, please contact us at support@trackandlist.com.
						</p>
						<h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">Changes to This Privacy Policy</h2>
						<p className="mt-6">
							We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. You are advised to review this Privacy Policy periodically for any changes.
						</p>
						<figure className="mt-10 border-l border-indigo-600 dark:border-indigo-400 pl-9">
							<blockquote className="font-semibold text-gray-900 dark:text-white">
								<p>
									“Your privacy is important to us. We are committed to protecting your personal information and ensuring transparency in how we handle it.”
								</p>
							</blockquote>
							<figcaption className="mt-6 flex gap-x-4">
								<div className="text-sm leading-6">
									<strong className="font-semibold text-gray-900 dark:text-white">The TrackNList Team</strong>
								</div>
							</figcaption>
						</figure>
						<p className="mt-10">
							Thank you for trusting TrackNList with your information. We are dedicated to safeguarding your privacy.
						</p>
					</div>
				</div>
			</div>
		</div>
	);
}
