import React from 'react';
import { CloudArrowUpIcon, LockClosedIcon, ServerIcon, BellIcon, ChatBubbleOvalLeftEllipsisIcon, HeartIcon } from '@heroicons/react/24/outline';

const features = [
	{
		name: 'Real-Time Inventory Tracking',
		description: 'Keep your inventory up-to-date across all platforms with real-time synchronization. Never worry about overselling or stock discrepancies again.',
		icon: CloudArrowUpIcon,
	},
	{
		name: 'Cross-Platform Listing',
		description: 'Easily list your products across multiple marketplaces with a single click. Save time and reach more customers effortlessly.',
		icon: ServerIcon,
	},
	{
		name: 'Email Tracking',
		description: 'Monitor new emails for order notifications and updates. Keep track of all your communications in one place.',
		icon: ChatBubbleOvalLeftEllipsisIcon,
	},
	{
		name: 'Notifications and Alerts',
		description: 'Receive notifications and alerts for all actions across different platforms. Stay informed and never miss an important update.',
		icon: BellIcon,
	},
	{
		name: 'Customer Engagement',
		description: 'Build strong relationships with your customers. Track interactions and provide personalized support to enhance customer satisfaction.',
		icon: HeartIcon,
	},
	{
		name: 'Secure and Reliable',
		description: 'Your data is protected with industry-standard security measures, ensuring that your inventory information is safe and secure.',
		icon: LockClosedIcon,
	},
];

const About: React.FC = () => {
	return (
		<div id="about" className="bg-white dark:bg-gray-800 py-24 sm:py-32 mt-[80px]">
			<div className="mx-auto max-w-7xl px-6 lg:px-8">
				<div className="mx-auto max-w-4xl text-center">
					<h2 className="text-3xl font-bold tracking-tight text-gray-900 dark:text-white sm:text-4xl">About TrackNList</h2>
					<p className="mt-6 text-lg leading-8 text-gray-600 dark:text-gray-300">
						TrackNList is your ultimate solution for seamless multi-platform inventory management. Our innovative platform is designed to simplify and streamline the process of managing and listing products across various online marketplaces, saving you time and maximizing your sales potential.
					</p>
				</div>
				<div className="mt-16 grid grid-cols-1 gap-x-8 gap-y-16 sm:grid-cols-2 lg:grid-cols-3">
					{features.map((feature) => (
						<div key={feature.name} className="flex flex-col items-center text-center">
							<feature.icon className="h-20 w-20 text-deepPurple mb-4" aria-hidden="true" />
							<h3 className="text-xl font-semibold text-gray-900 dark:text-white">{feature.name}</h3>
							<p className="mt-2 text-base leading-7 text-gray-600 dark:text-gray-300">
								{feature.description}
							</p>
						</div>
					))}
				</div>
				<div className="mt-24 text-center">
					<h3 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white sm:text-3xl">Our Commitment</h3>
					<p className="mt-6 text-lg leading-8 text-gray-600 dark:text-gray-300">
						At TrackNList, we are committed to building a great and efficiently well-tested product to meet your demands. Our team works tirelessly to ensure that our platform is reliable, secure, and packed with features that help you succeed in your business. We continuously test and improve our product to provide you with the best possible experience.
					</p>
				</div>
			</div>
		</div>
	);
};

export default About;
